<template>
  <div
    class="absolute bottom-0 right-0 w-full min-w-full flex !text-iq-white flex-col p-2 border-t border-solid bg-iq-red border-t-solid border-t-iq-white/40 z-50"
    :class="{ 'lg:w-[calc(100%-424px)]': !props.isOnLandingPage }"
  >
    <div
      v-if="props.showButton"
      class="flex items-center justify-center p-2 text-center !text-iq-white bg-iq-red text-regular-16 md:text-regular-16"
    >
      {{ t('signUpAndReceive') }}
    </div>
    <div
      v-if="!props.showButton"
      class="flex flex-row items-center h-8 pt-4 lg:pt-8 place-content-center"
    >
      <TheSpinner />
      <div class="ml-4 text-regular-14 !text-iq-white md:text-regular-18">
        {{ loadingText }}
      </div>
    </div>
    <div
      v-if="props.showButton"
      class="flex items-center justify-center w-full p-2"
    >
      <UiButton
        class="iq-button !w-full border shadow-lg iq-hover lg:!w-2/5 !border-iq-black !bg-iq-grey-dark !text-iq-beige !tracking-iq-wide lg:!h-12.5 !h-16"
        @click="emit('click:footer:cta')"
        >{{ props.buttonText }}</UiButton
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onUnmounted } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n({ useScope: 'local' });

const emit = defineEmits<{
  (event: 'click:footer:cta'): void;
}>();

const props = defineProps<{
  loadingText: string;
  showButton?: boolean;
  buttonText?: string;
  isOnLandingPage?: boolean;
}>();

let interval: number;

onUnmounted(() => {
  clearInterval(interval);
});
</script>

<i18n lang="json">
{
  "en": {
    "signUpAndReceive": "Sign up and receive 3 more scans"
  },
  "de": {
    "signUpAndReceive": "Melde dich an und erhalte 3 weitere Scans"
  }
}
</i18n>
