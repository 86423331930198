<script setup lang="ts">
import { cn } from '@/lib/utils';
import { ComboboxInput, type ComboboxInputProps } from 'radix-vue';

const props = defineProps<ComboboxInputProps & { disabled?: boolean }>();
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<template>
  <div class="flex items-center w-full" cmdk-input-wrapper>
    <ComboboxInput
      v-bind="{ ...props, ...$attrs }"
      auto-focus
      :disabled="props.disabled"
      :class="
        cn(
          'flex h-15 w-full text-regular-20 shadow-iq-inset !text-iq-grey-dark rounded-none !bg-iq-beige  p-3 !pr-6.5 outline-none placeholder:!text-iq-grey-light placeholder:text-regular-20 disabled:cursor-not-allowed disabled:opacity-50',
          $attrs.class ?? ''
        )
      "
    />
  </div>
</template>
