<template>
  <div class="relative flex flex-row items-center justify-between">
    <UiCommand>
      <div class="relative flex w-full min-w-[160px]">
        <UiCommandInput
          ref="inputRef"
          :value="internalLink"
          spellcheck="false"
          autocomplete="url"
          type="url"
          :disabled="props.disabled"
          :class="`command-input-${props.id} ${
            props.error ? 'border-[1px] border-iq-red !text-iq-red' : ''
          }`"
          :placeholder="placeholder"
          @input="updateValue"
          @focus="hasFocus = true"
        />
        <div class="absolute right-2 top-5.5">
          <div
            v-if="!!internalLink && !props.isLoading"
            class="relative flex items-center p-[1px]"
            :class="{
              '!bg-[#89827b] hover:cursor-not-allowed': props.disabled,
              '!bg-iq-beige': !props.disabled,
            }"
            @click="onClickDelete"
          >
            <Icon
              name="heroicons:x-mark-solid"
              :class="{
                '!bg-[#89827b] hover:cursor-not-allowed': props.disabled,
              }"
              class="w-4 h-4 !text-iq-black iq-hover"
            />
          </div>
        </div>
        <AppScanInputProgress
          v-if="props.isLoading"
          class="absolute w-full"
          :text="progressText"
          :progress="progress"
        />
      </div>
      <UiCommandList
        v-if="
          (filteredProfiles?.length ||
            filteredProducts?.length ||
            showAddProduct) &&
          hasFocus &&
          !profile &&
          !product
        "
        class="absolute z-20 w-full border-t bg-iq-black top-[62px] border-t-iq-white"
        :class="`command-list-${props.id}`"
      >
        <UiCommandGroup heading="">
          <UiCommandItem
            v-if="props.showAddProduct"
            class="iq-hover"
            :value="(`PRODUKT HINZUFÜGEN` as string)"
            @click="emit('click:addProduct')"
          >
            <span class="text-center">
              {{ t('add-product') }}
            </span>
          </UiCommandItem>
        </UiCommandGroup>
        <UiCommandGroup v-if="filteredProfiles" :heading="t('profiles')">
          <UiCommandItem
            v-for="(filteredProfile, index) in filteredProfiles"
            :key="filteredProfile.id"
            class="iq-hover"
            :value="(`${filteredProfile.name}-${index}` as string)"
            @click="
              emit('set:profile', {
                profile: filteredProfile,
                isBaseProfile: isBaseProfile!,
              })
            "
          >
            <div class="flex gap-1 items-center">
              <Icon
                :name="`flag:${
                  filteredProfile?.language === 'EN'
                    ? 'gb'
                    : Language[filteredProfile?.language]?.toLowerCase()
                }-4x3`"
                class="w-5 h-3"
              />
              {{ filteredProfile.name }}
            </div>
          </UiCommandItem>
        </UiCommandGroup>
        <UiCommandSeparator />
        <UiCommandGroup v-if="filteredProducts" :heading="t('products')">
          <UiCommandItem
            v-for="(filteredProduct, index) in filteredProducts"
            :key="filteredProduct.id"
            :value="`${filteredProduct.name}-${index}`"
            class="iq-hover"
            @click="emit('set:product', filteredProduct)"
          >
            <div class="flex gap-1 items-center">
              <Icon
                :name="`flag:${
                  filteredProduct?.language === 'EN'
                    ? 'gb'
                    : Language[filteredProduct.language as Language]?.toLowerCase()
                }-4x3`"
                class="w-5 h-3"
              />
              {{ filteredProduct.name }}
            </div>
          </UiCommandItem>
        </UiCommandGroup>
      </UiCommandList>
    </UiCommand>
  </div>
</template>

<script setup lang="ts">
import type { CompanyProfile } from '@/interfaces/company-profile.interface';
import type { DetailedProduct, Profile } from '@prisma/client';
import { Language } from '~/enum/language.enum';
const props = defineProps<{
  disabled?: boolean;
  error?: boolean;
  id: string;
  isBaseProfile?: boolean;
  isLoading: boolean;
  modelValue?: string;
  placeholder: string;
  product?: DetailedProduct;
  products?: DetailedProduct[];
  profile?: CompanyProfile | Profile;
  profiles?: CompanyProfile[];
  progress: number;
  progressText: string;
  showAddProduct?: boolean;
}>();

const emit = defineEmits<{
  (event: 'update:modelValue', value: string): void;
  (event: 'click:addProduct'): void;
  (
    event: 'set:profile',
    value: { profile: CompanyProfile; isBaseProfile: boolean }
  ): void;
  (event: 'set:product', value: DetailedProduct): void;
}>();

const hasFocus = ref(false);
const inputRef = ref();
const internalLink = useVModel(props, 'modelValue', emit);

const { t } = useI18n({ useScope: 'local' });

const filteredProfiles = computed(() => {
  if (!internalLink.value) {
    return props.profiles;
  }
  return props.profiles?.filter((profile) => {
    const name = profile.name.toLowerCase();
    const value = internalLink.value?.toLowerCase();
    const index = name.indexOf(value!);

    if (index !== -1) {
      return profile;
    }
  });
});

const filteredProducts = computed(() => {
  if (!internalLink.value) {
    return props.products;
  }
  return props.products?.filter((product) => {
    const name = product.name.toLowerCase();
    const value = internalLink.value?.toLowerCase();
    const index = name?.indexOf(value!);

    if (index !== -1) {
      return product;
    }
  });
});

const handleOutsideClick = (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (
    !target?.closest(`.command-input-${props.id}`) &&
    !target?.closest(`.command-list-${props.id}`)
  ) {
    hasFocus.value = false;
  }
};

const updateValue = (event: Event) => {
  const target = event.target as HTMLInputElement;
  internalLink.value = target?.value;
};

function onClickDelete() {
  if (props.disabled) {
    return;
  }
  internalLink.value = undefined;
}

watch(internalLink, (newValue) => {
  hasFocus.value = !newValue?.includes('.');
});

onMounted(() => {
  document.addEventListener('click', handleOutsideClick);
});
onUnmounted(() => {
  document.removeEventListener('click', handleOutsideClick);
});
</script>
<i18n lang="json">
{
  "de": {
    "profiles": "Profile",
    "products": "Produkte",
    "add-product": "Alternativ Produkt per Dokument oder Webseite hinzufügen"
  },
  "en": {
    "profiles": "Profiles",
    "products": "Products",
    "add-product": "As an alternative, add a product via document or website."
  }
}
</i18n>
